<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.update || !subPermission.store"
    />

    <b-card :title="$t('g.techEvaluationsReviews')" v-else>
      <!-- form -->

      <b-form>
        <b-row>
          <!-- accepted -->
          <b-col md="4">
            <b-form-group :label="$t('g.isTechEvaluationReportAccepted')">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-select
                  v-model="form_data.is_tech_evaluation_report_accepted"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="available_options"
                  label="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- ** accepted -->

          <!-- ** rejection_reason -->
          <b-col md="10">
            <b-form-group :label="$t('g.techEvaluationRejectionReason')">
              <b-form-textarea
                v-model="form_data.tech_evaluation_rejection_reason"
              />
            </b-form-group>
          </b-col>
          <!-- **  rejection_reason -->

          <!-- Submit Button -->
          <b-col cols="12">
            <b-button
              :variant="isEdit ? 'success' : 'primary'"
              type="submit"
              @click.prevent="validationForm"
            >
              {{ isEdit ? $t("g.edit") : $t("g.submit") }}
            </b-button>
          </b-col>
          <!-- ** Submit Button -->
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BCard,
    Loading,
    Error,
    ContentNotView,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  data() {
    return {
      form_data: {
        is_tech_evaluation_report_accepted: 0,
        tech_evaluation_rejection_reason: null,
      },

      available_options: [
        {
          value: null,
          text: this.$t("g.Please_Select_Status"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.acceptable"),
        },
        {
          value: 0,
          text: this.$t("g.notAcceptable"),
        },
      ],

      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      techId: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddTechEvaluationsReviews") {
        this.handleWatchRoutePage();
      }
    },
  },

  created() {
    this.currentId = this.$route.params.id;
    if (this.$route.name === "EditTechEvaluationsReviews") {
      this.isEdit = true;
      this.techId = this.$route.params.techId;

      this.getTechEvaluationsReviews();
    }
  },

  beforeMount() {
    setTimeout(() => {
      this.pageStatus(false, false);
      store.dispatch("GET_PERMISSION", "techEvaluationsReviews");
      this.subPermission = store.state.permissions.sub;
    }, 2000);
  },

  methods: {
    //  GET DATA SUPPLIER IF isEdit true
    getTechEvaluationsReviews() {
      this.$http
        .get(
          `admin/serviceRequests/${this.currentId}/techEvaluationsReviews/${this.techId}`
        )
        .then((response) => {
          // if (response.status === 200) {
          //   const data = response.data.data;
          //   this.form_data.tech_evaluation_rejection_reason =
          //     data?.tech_evaluation_rejection_reason;
          //   this.form_data.is_tech_evaluation_report_accepted =
          //     data?.is_tech_evaluation_report_accepted ? 1 : 0;
          // }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isEdit = false;
      this.$refs.form.reset();
      this.clearForm();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      this.form_data.is_tech_evaluation_report_accepted = 1;
      this.form_data.tech_evaluation_rejection_reason = null;
    },
    // Send the modified data
    // submitEdit() {

    //   // append data to FormData object before sending to backend
    //   let formData = new FormData();
    //   for (let item in this.form_data) {
    //     formData.append(item, this.form_data[item]);
    //   }

    //   this.$http
    //     .post(
    //       `admin/serviceRequests/${this.currentId}/techEvaluationsReviews/${this.techId}?_method=put`,
    //       formData
    //     )
    //     .then((res) => {
    //
    //       this.makeToast(
    //         "success",
    //         this.$t("g.send.successBody"),
    //         this.$t("g.send.successTitle")
    //       );
    //       setTimeout(() => {
    //         this.$router.push({ name: "ListTechEvaluationsReviews" });
    //       }, 500);
    //     })
    //     .catch((err) => {
    //       for (const key in err.response.data.errors) {
          //   this.makeToast(
          //     "danger",
          //     err.response.data.errors[key][0],
          //     this.$t("g.send.errorTitle")
          //   );
          // }
          // console.log(err);
    //     });
    // },
    //  Submit new user
    submitNewAddition() {
      console.log(this.form_data);
      // append data to FormData object before sending to backend
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post(
          `admin/serviceRequests/${this.currentId}/techEvaluationsReviews`,
          formData
        )
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "ListTechEvaluationsReviews" });
          }, 1000);
        })
        .catch((err) => {
          console.log(err.message);
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
    //  Submit Function
    validationForm() {
      this.form_data.is_tech_evaluation_report_accepted = Number(
        this.form_data.is_tech_evaluation_report_accepted
      );
      if (this.form_data.tech_evaluation_rejection_reason === null) {
        delete this.form_data.tech_evaluation_rejection_reason;
      }
      if (this.isEdit) {
        return this.submitEdit();
      } else {
        this.submitNewAddition();
      }
    },
  },
};
</script>

<style></style>
